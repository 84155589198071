import { useEffect, useState } from 'react';
import './App.css';
import Socials from './components/Socials';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { Fade, Bounce, Slide, Zoom } from "react-awesome-reveal";
import imageUrlDesktop from './background-2.jpg'
import imageUrlMobile from './background-3.jpg'


function App() {
  const [formStatus, setFormStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loaded, setLoaded] = useState(false);
  const [source, setSource] = useState(null)

  const {
    register,
    handleSubmit,
    reset,
    formState: {
      errors,
      isSubmitSuccessful
    }
  } = useForm();

  useEffect(() => {
    if (isSubmitSuccessful) {

      setTimeout(() => {
        setFormStatus(isSubmitSuccessful)
      }, 500);
    }
  }, [isSubmitSuccessful])

  const onSubmit = data => {
    const API_URL = process.env.REACT_APP_BACKEND_API
    console.log(API_URL)
    // axios.get(`${API_URL}/sanctum/csrf-cookie`)
    //   .then(response => {
    const fd = new FormData()
    fd.append('name', data.name)
    fd.append('email', data.email)
    fd.append('agreement', data.agreement)

    const config = {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf',
      },
    }

    setIsLoading(true)

    axios.post(`${API_URL}/api/form-store`, fd, config)
      .then(response => {

        // set loading to false
        setIsLoading(false)

        // reset form
        reset()

        setTimeout(() => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Rizome Brochure.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }, 1000);
      })
      .catch((err) => {
        let message = typeof err.response !== "undefined" ? err.response.data.message : err.message;
        console.warn("error", message);

        // set loading to false
        setIsLoading(false)
      })
    // })
  };

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response) {
        console.log('Error status:', error.response.status);
        console.log('Error data:', error.response.data);
        if (error.response.status === 400) {
          // Handle 400 Bad Request error
          console.log(error.response.data)
        } else if (error.response.status === 401) {
          // Handle 401 Unauthorized error
          console.log(error.response.data)
        } else if (error.response.status === 422) {
          console.log(error.response.data)
        } else {
          // Handle other error codes
        }

        // set loading to false
        setIsLoading(false)
      } else if (error.request) {
        console.log('No response received:', error.request);
      } else {
        console.log('Error occurred:', error.message);
      }
      return Promise.reject(error);
    });

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);


  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  function supportsSafariAndChrome() {
    // return /Chrome/.test(navigator.userAgent) && /Safari/.test(navigator.userAgent);
    return true
  }

  useEffect(() => {
    setSource( isMobileDevice() && supportsSafariAndChrome() ? imageUrlMobile: imageUrlDesktop)

    console.log(isMobileDevice(), supportsSafariAndChrome())
  }, [])

  useEffect(() => {
    const image = new Image();
    image.src = source;
    image.onload = () => {
      setLoaded(true);
    };
  }, [source]);

  return (
    <div className="App">
      <main className={`App-content App-bg App-bg-desktop ${loaded ? 'loaded' : ''}`} style={{ backgroundImage: `url(${source})` }}>
        <div className="container mx-auto sm:flex items-center">
          <div className="grid grid-cols-12 w-full">
            <div className={`lg:col-span-6 App-bg App-bg-mobile col-span-12 sm:p-10 p-5 py-10 flex flex-col items-center sm:justify-center gap-20 sm:h-initial h-[500px] ${loaded ? 'loaded' : ''}`} style={{ backgroundImage: `url(${source})` }}>
              {/* <Fade> */}
              <div className='flex flex-col items-center justify-between h-full'>
                <h1 className="text-3xl lg:text-[3.25rem] mb-5 lg:leading-[1] md:text-4xl text-center uppercase font-bebas headline">
                  Shape tomorrow's architecture <br />
                  in your projects today with <br />
                  the miracle timber
                </h1>
                <div className='Brand flex justify-center'>
                  <img src="/img/Rizome-Logo.png" className='sm:w-[200px]' alt="" />
                </div>
              </div>
              {/* </Fade> */}
              <div className='md:block hidden mt-auto'>
                <Socials />
              </div>
            </div>
            <div className="lg:col-span-6 col-span-12 sm:p-5 sm:h-full flex items-center lg:justify-end justify-center">
              <div className='xl:w-[525px] w-full'>

                <Slide direction='down' duration={500}>
                  <div className="md:p-10 sm:p-5 p-10 bg-primary w-full md:min-h-[415px] min-h-[275px] relative">
                    {formStatus ?
                      <div className='absolute inset-0 flex items-center justify-center text-center md:p-0 p-5'>
                        <div>
                          <Zoom>
                            <div className='relative mb-10 before:content-[""] before:absolute before:w-5/12 before:h-[2px] before:left-1/2 before:top-[120%] before:-translate-x-1/2 before:bg-white'>
                              <h1 className="text-2xl md:text-4xl text-center font-bold" style={{ lineHeight: 1 }}>
                                Thank you<br />
                                for your input!
                              </h1>
                            </div>
                            <p className='text-white'>If you have any questions, feel free to reach us <br className='md:block hidden' />through our social media pages.</p>
                          </Zoom>
                        </div>
                      </div>
                      :
                      <>
                        <div className="text-white font-bold sm:text-lg text-2xl mb-8">Sign up and get your FREE <span className='text-nowrap whitespace-nowrap'>e-brochure</span> instantly!</div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className='mb-3'>
                            <label for="name" className='font-[600]'>Full Name</label>
                            <input type="text" name='name' id='name'
                              {...register('name', {
                                required: {
                                  value: true,
                                  message: 'Full Name is required',
                                },
                              })}
                            />
                            <label className="level" for="name">
                              {errors.name?.type === 'required' && (
                                <span className="label-text-alt text-sm text-danger">
                                  {errors.name.message}
                                </span>
                              )}
                            </label>
                          </div>
                          <div className='mb-8'>
                            <label for="email" className='font-[600]'>Email Address</label>
                            <input type="text" name='email' id='email'
                              {...register('email', {
                                required: {
                                  value: true,
                                  message: 'Email Address is required',
                                },
                                pattern: {
                                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
                                  message: 'Please enter valid email address',
                                },
                              })}
                            />
                            <label className="level" for="email">
                              {errors.email?.type === 'required' && (
                                <span className="label-text-alt text-sm text-danger">
                                  {errors.email.message}
                                </span>
                              )}
                              {errors.email?.type === 'pattern' && (
                                <span className="label-text-alt text-sm text-danger">
                                  {errors.email.message}
                                </span>
                              )}
                            </label>
                          </div>
                          <div className='flex justify-center mb-8'>
                            <div className="sm:w-10/12">
                              <label class="checkmark-container">I acknowledge and consent sharing my information which may be used for future offers and promotions.
                                <input type="checkbox" name='agreement' id='agreement'
                                  {...register('agreement', {
                                    required: {
                                      value: true,
                                      message: 'To proceed, please indicate that you agree by checking the box.',
                                    },
                                  })}
                                />
                                <span class="checkmark"></span>
                              </label>
                              <label className="level" for="agreement">
                                {errors.agreement?.type === 'required' && (
                                  <span className="label-text-alt text-sm text-danger">
                                    {errors.agreement.message}
                                  </span>
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="flex justify-center">
                            <button type='submit' className='bg-dark border md:min-w-[275px] font-bold'>{isLoading ? 'Processing...' : 'Submit & unlock your brochure'}</button>
                          </div>
                        </form>
                      </>

                    }

                  </div>
                </Slide>
              </div>

            </div>
            <div className='md:hidden block col-span-12 sm:mb-5 sm:bg-transparent bg-black sm:p-0 p-5'>
              <Socials />
            </div>
          </div>

        </div>
      </main>
    </div>
  );
}

export default App;
