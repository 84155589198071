import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { BsGlobe2 } from "react-icons/bs";
import { Fade, Bounce } from "react-awesome-reveal";

const socials = [
  {
    key: "facebook",
    icon: <FaFacebookF/>,
    href: "https://www.facebook.com/rizomephilippines/",
  },
  {
    key: "instagram",
    icon: <FaInstagram/>,
    href: "https://www.instagram.com/rizomebamboo/",
  },
  {
    key: "linkedin",
    icon: <FaLinkedinIn/>,
    href: "https://www.linkedin.com/company/rizomebamboo/",
  },
  {
    key: "website",
    icon: <BsGlobe2/>,
    href: "https://rizomephilippines.com/",
  },
]

export default function Socials() {
  return (
    <div className="Socials text-center">
      <div className="text-white font-bold text-lg">Stay connected!</div>
      <div className="flex gap-2 justify-center items-center my-2" id="socials">
        {
          socials.map(item => (
            <Bounce cascade>
              <a href={item.href} target="_blank" rel="noreferrer" key={item.key} className="s-item flex justify-center items-center bg-white w-10 h-10 rounded-full cursor-pointer">
                <div className="s-icon text-black text-2xl self-center">
                  {item.icon}
                </div>
              </a>
            </Bounce>
          ))
        }
       
      </div>
      <div className="text-white font-normal sm:text-lg">Follow us for the latest updates, content, and more.</div>
    </div>
  )
}

